import React from "react";

export const Checkbox = ({
  id,
  value,
  onChange,
  label,
  handleChange,
  setValue,
}) => {
  return (
    <div
      className="checkbox-input"
      onClick={() =>
        handleChange ? handleChange(!value, setValue) : onChange(!value)
      }
    >
      <input type="checkbox" id={id} checked={value} />
      <div className="label">{label}</div>
    </div>
  );
};
