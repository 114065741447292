import React from "react";
import PropTypes from "prop-types";
import Link from "../Link";

// Button
export const Button = ({
  id,
  loader,
  variant,
  link,
  target,
  type,
  size,
  className,
  onClick,
  children,
  disabled,
}) => {
  const btnSize = () => {
    if (size === "sm") {
      return "btn-sm";
    } else if (size === "lg") {
      return "btn-lg";
    } else {
      return "";
    }
  };

  if (link) {
    return (
      <Link
        href={link}
        target={target}
        className={`btn btn-${variant} ${btnSize()} ${className} ${loader ? "loading" : ""}`}
        id={id}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{children}</span>
        {loader && <div className="el-loader" />}
      </Link>
    );
  } else if (type) {
    return (
      <button
        type={type}
        className={`btn btn-${variant} ${btnSize()} ${className} ${loader ? "loading" : ""}`}
        id={id}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{children}</span>
        {loader && <div className="el-loader" />}
      </button>
    );
  } else {
    return (
      <button
        className={`btn btn-${variant} ${btnSize()} ${className} ${loader ? "loading" : ""}`}
        id={id}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{children}</span>
        {loader && <div className="el-loader" />}
      </button>
    );
  }
};

Button.defaultProps = {
  size: "",
  target: null,
  loader: null,
  className: "",
  variant: "primary",
};
