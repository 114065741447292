import React, { useRef } from "react";

//Utils
import { Portal } from "react-portal";

import dynamic from "next/dynamic";
import { HookForm, HookInput, Button, CommonInput } from "@components/anti";

//Component
const BarcodeScannerComponent = dynamic(
  () => import("react-qr-barcode-scanner"),
  {
    ssr: false,
  }
);

export const ModalCamera = ({
  isShowing,
  id,
  hide = () => {},
  onError = () => {},
  onUpdate = () => {},
  modifiedValue,
  onCallback,
}) => {
  const scrollView = useRef(null);
  const [phone, setPhone] = React.useState(null);
  const [valueBarcode, setValueBarcode] = React.useState("");

  React.useEffect(() => {
    if (modifiedValue?.value) setValueBarcode(modifiedValue.value);
  }, [modifiedValue]);

  return (
    <>
      {isShowing && isShowing === id ? (
        <Portal node={document?.getElementById("camera-portal")}>
          <div className="modal-camera-cashiers">
            <div className="camera-wrapper">
              <BarcodeScannerComponent
                onError={() => {}}
                onUpdate={(err, result) => {
                  if (result) {
                    setValueBarcode(result.text);
                    scrollView.current?.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              />
            </div>
            <div className="scanner-info fadeInUp pt-5">
              <div className="close" onClick={hide}>
                <i className="air ai-times"></i>
              </div>
              <div className="info">
                <div className="container">
                  <p className="title text-center">Scan Barcode Voucher</p>
                  <p className="text-center">
                    Posisikan barcode voucher pada posisi yang di sediakan, atau
                    anda bisa input manual kode voucher pelanggan
                  </p>

                  <div className="py-3">
                    <CommonInput
                      id="voucer-camera-input"
                      value={valueBarcode}
                      type="text"
                      onChange={(e) => setValueBarcode(e.target.value)}
                      placeholder="Enter vouchers"
                    />
                  </div>

                  <div className="camera-form-vouchers">
                    <div className="mt-3">
                      <Button
                        className="w-100"
                        onClick={() => {
                          setValueBarcode(null);
                          onCallback(valueBarcode);
                        }}
                      >
                        LANJUTKAN
                      </Button>
                    </div>
                    {/* <h4 className="text-center">{phone}</h4> */}
                  </div>
                  <div ref={scrollView}></div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};
