import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePickerReact from "react-datepicker";
//Utils
import { registerLocale, setDefaultLocale } from "react-datepicker";
import langId from "date-fns/locale/id";

export const DatePickerInput = ({
  id,
  className,
  label,
  labelClassName,
  suffix,
  prefix,
  inputClassName,
  value,
  onChange,
  errorText,
  placeholder,
}) => {
  //Utils
  registerLocale("id", langId);

  setDefaultLocale("id");

  return (
    <div className={`field field-basic ${className} `}>
      {label && (
        <label className={labelClassName} htmlFor={id || name}>
          {label}
        </label>
      )}
      <div className="d-flex align-items-center">
        <div
          className={`form-control-wrapper w-100 ${prefix ? "prefix" : ""}  ${
            suffix ? "suffix" : ""
          }`}
        >
          <div className="input wrapper">
            <div className="input-with-prefix-suffix">
              {prefix ? (
                <span className="prefix-content ">{prefix}</span>
              ) : null}
              <DatePickerReact
                locale={"id"}
                onChange={onChange}
                className={inputClassName}
                selected={value}
                // placeholderText={placeholder}
                maxDate={new Date()}
                popperPlacement="top-start"
              />
              {suffix ? (
                <span className="suffix-content ">{suffix()}</span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommonInput = ({
  id,
  name,
  type,
  variant,
  label,
  value,
  placeholder,
  labelClassName,
  inputClassName,
  disabled,
  suffix,
  prefix,
  className,
  onChange,
  errorText,
  isError,
  inputMode = "text",
}) => {
  return (
    <div className={`field field-basic ${className} `}>
      {label && (
        <label className={labelClassName} htmlFor={id || name}>
          {label}
        </label>
      )}
      <div className="d-flex align-items-center">
        <div
          className={`form-control-wrapper w-100 ${prefix ? "prefix" : ""}  ${
            suffix ? "suffix" : ""
          }`}
        >
          <div className="input wrapper">
            <div className="input-with-prefix-suffix">
              {prefix ? (
                <span className="prefix-content ">{prefix}</span>
              ) : null}
              <input
                id={id}
                name={name}
                type={type}
                inputMode={inputMode}
                placeholder={placeholder}
                className={`form-control ${
                  isError ? "error-border" : ""
                } ${inputClassName}`}
                disabled={disabled}
                value={value}
                onChange={onChange}
              />
              {suffix ? (
                <span className="suffix-content ">{suffix()}</span>
              ) : null}
            </div>
          </div>
          {isError ? (
            <p className="m-0 voucher-error-text">
              {errorText ? errorText : "Kode voucher salah"}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

CommonInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  variant: PropTypes.oneOf(["basic", "underline"]),
  theme: PropTypes.oneOf(["light", "dark"]),
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.string,
  value: PropTypes.string,
  floatingLabel: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};

CommonInput.defaultProps = {
  id: "",
  type: "text",
  variant: "basic",
  theme: "light",
  label: null,
  as: "input",
  placeholder: "Enter text",
  required: false,
  className: "",
  labelClassName: "",
  inputClassName: "",
  wrapperClassName: "",
  hideValidationText: false,
  disabled: false,
};
