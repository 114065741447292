import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

export const HookForm = ({
  id,
  name,
  children,
  onSubmit,
  className,
  defaultValues,
  setValueCallback,
  // fieldArrayName,
  // fieldArrayRules,
  mode = "onChange",
}) => {
  const [values, setValues] = useState({});
  const {
    watch,
    control,
    trigger,
    setValue,
    register,
    setError,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode, defaultValues });

  // const fieldArray = useFieldArray({
  //   control, // control props comes from useForm (optional: if you are using FormContext)
  //   name: fieldArrayName, // unique name for your Field Array
  //   rules: fieldArrayRules,
  // });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setValues((prev) => ({ ...prev, ...value }));
    });
    if (defaultValues) setValues(defaultValues);
    return () => subscription.unsubscribe();
  }, [getValues, watch, defaultValues]);

  useEffect(() => {
    if (setValueCallback) {
      setValueCallback(setValue);
    }
  }, []);

  return (
    <form
      id={id}
      className={className}
      name={name}
      onSubmit={handleSubmit((v) => onSubmit(v, reset))}
    >
      {typeof children === "function"
        ? children({
            watch,
            values,
            errors,
            isValid,
            control,
            trigger,
            setValue,
            setError,
            register,
            getValues,
            defaultValues,
            // fieldArray,
          })
        : React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              watch,
              values,
              errors,
              isValid,
              control,
              trigger,
              setValue,
              setError,
              register,
              getValues,
              defaultValues,
              // fieldArray,
            });
          })}
    </form>
  );
};
