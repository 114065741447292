import ReactDatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import PropTypes from "prop-types";

export const DatePicker = ({
  open,
  name,
  value,
  format,
  errors,
  minDate,
  touched,
  disabled,
  onChange,
  className,
  errorHooks,
  customInput,
  placeholder,
  defaultValue,
  wrapperClassName,
  showYearDropdown,
  maxDate,
}) => {
  return (
    <ReactDatePicker
      name={name}
      dateFormat={format}
      disabled={disabled}
      placeholderText={placeholder}
      selected={value || defaultValue}
      showYearDropdown={showYearDropdown}
      wrapperClassName={wrapperClassName}
      className={`form-control ${className} ${
        (touched && errors && touched[name] && errors[name]) || errorHooks
          ? "error"
          : ""
      }`}
      maxDate={maxDate}
      onChange={onChange}
      customInput={customInput}
    />
  );
};

DatePicker.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  customInput: PropTypes.object,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  showYearDropdown: PropTypes.bool,
};

DatePicker.defaultProps = {
  name: "",
  className: "",
  placeholder: "",
  wrapperClassName: "",
  format: "dd/MM/yyyy",
  showYearDropdown: true,
  open: false,
  customInput: (
    <MaskedTextInput
      type="text"
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    />
  ),
};
